<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div class="view-modal">
    <el-dialog
      :fullscreen="true"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="container-info">
        <div class="information">
          <h2>Entry #{{ dataOrder.uuid }}</h2>
          <span>{{ dataOrder.created_at }}</span>

          <section class="form-info">
            <div class="d-flex flex-direction">
              <label>First name</label>
              <span>{{ dataOrder.firstname }}</span>
            </div>

            <div class="d-flex flex-direction">
              <label>Last name</label>
              <span>{{ dataOrder.lastname }}</span>
            </div>

            <div class="d-flex flex-direction">
              <label>Email</label>
              <span>{{ dataOrder.email }}</span>
            </div>

            <div class="d-flex flex-direction">
              <label>Phone Number</label>
              <span>{{ dataOrder.phone }}</span>
            </div>

            <div class="d-flex flex-direction" style="grid-column: 1 / span 2;">
              <label>Billing Address</label>
              <span>{{ dataOrder.billing_address }}</span>
            </div>

            <div class="d-flex flex-direction" style="grid-column: 1 / span 2;">
              <label>Billing Address2</label>
              <span>{{ dataOrder.billing_address2 }}</span>
            </div>

            <div class="d-flex flex-direction" style="grid-column: 1 / span 2;">
              <label>State</label>
              <span>{{ dataOrder.billing_state_name }}</span>
            </div>

            <div class="d-flex flex-direction">
              <label>City</label>
              <span>{{ dataOrder.billing_city }}</span>
            </div>

            <div class="d-flex flex-direction">
              <label>Zip CODE</label>
              <span>{{ dataOrder.billing_zip }}</span>
            </div>

            <div class="d-flex flex-direction" style="grid-column: 1 / span 2;">
              <label>Shipping Address</label>
              <span>{{ dataOrder.shipping_address }}</span>
            </div>

            <div class="d-flex flex-direction" style="grid-column: 1 / span 2;">
              <label>Shipping Address2</label>
              <span>{{ dataOrder.shipping_address2 }}</span>
            </div>

            <div class="d-flex flex-direction" style="grid-column: 1 / span 2;">
              <label>State</label>
              <span>{{ dataOrder.shipping_state_name }}</span>
            </div>

            <div class="d-flex flex-direction">
              <label>City</label>
              <span>{{ dataOrder.shipping_city }}</span>
            </div>

            <div class="d-flex flex-direction">
              <label>Zip CODE</label>
              <span>{{ dataOrder.shipping_zip }}</span>
            </div>

            <div class="d-flex flex-direction" style="grid-column: 1 / span 2;">
              <label>Size</label>
              <span>{{ dataOrder.detail[0].fields.size.toUpperCase() }}</span>
            </div>
          </section>
        </div>
      </div>

      <div class="container-radio">

        <div class="container-buttons">
          <div>
            <el-button
              :disabled="this.dataOrder?.id === prevEntryNumber || !prevEntryNumber"
              @click="handlePrevious"
            >
              <b>Previous entry</b> <br/> Entry #{{ prevEntryNumber }}
            </el-button>
            <el-button
              @click="handleNext"
              :disabled="this.dataOrder?.id === nextEntryNumber || !nextEntryNumber"
            >
              <b>Next entry</b> <br/> Entry #{{ nextEntryNumber }}
            </el-button>
          </div>
        </div>

        <div class="container-radios">
          <h2>Status</h2>

          <el-radio-group
            @change="handleChangeStatus"
            v-model="statusSelected"
          >
            <el-radio
              v-for="(_i, statusType) in orderStatusPermitted"
              :key="statusType"
              :label="statusType"
              border
            >
              {{ statusType }}
            </el-radio>
          </el-radio-group>

          <el-input
            v-if="showTrackingNumber"
            placeholder="Input tracking number"
            v-model="trackingNumber"
            class="input-tracking-number"
          ></el-input>

          <h2>Shipping status</h2>

          <el-radio-group v-model="shippingStatusSelected">
            <el-radio
              v-for="(status, index) in shippingStatus"
              :key="index"
              :label="index"
              border
              disabled
            >
              {{ status }}
            </el-radio>
          </el-radio-group>
        </div>

        <div class="container-buttons">
          <div>
            <el-button type="info" plain @click="handleClose">Cancel</el-button>
            <el-button type="primary" @click="handleSave">Save</el-button>
          </div>
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import ApiCMS from '@/lib/ApiCMS';

export default {
  name: 'ViewModal',
  props: {
    data: Object,
    filtersQuery: String,
    showModal: Boolean,
    statusTypesPermissions: Object,
  },
  data() {
    return {
      arraySizes: {
        1: "5'6''",
        2: "5'11''",
      },
      dataOrder: {},
      dialogVisible: this.showModal,
      nextEntryNumber: 0,
      prevEntryNumber: 0,
      shippingStatus: {
        sent: 'Sent',
        no_sent: 'No sent',
      },
      shippingStatusSelected: 'no_sent',
      showTrackingNumber: false,
      statusSelected: '',
      trackingNumber: '',
      optin: false,
    };
  },
  async created() {
    this.dataOrder = this.data;
    this.optin = this.data.optin === 1;
    this.statusSelected = this.data.status;

    if (this.data.status === 'shipped') {
      this.showTrackingNumber = true;
      this.trackingNumber = this.data.tracking_id;
      this.shippingStatusSelected = this.data.tracking_id ? 'sent' : 'no_sent';
    }

    const { next, prev } = await ApiCMS.getOne(`orders/${this.data.id}/nextprev/?${this.filtersQuery}`);
    this.nextEntryNumber = next?.id ?? this.data.id;
    this.prevEntryNumber = prev?.id ?? this.data.id;
  },
  computed: {
    orderStatusPermitted() {
      const cleanedStatusTypes = Object.fromEntries(
        Object.entries(this.statusTypesPermissions).filter((item) => item[0] !== 'download_csv'),
      );
      return cleanedStatusTypes;
    },
  },
  methods: {
    async getInfoOrder(idOrder) {
      const { data, success } = await ApiCMS.getOne(`orders/${idOrder}/?${this.filtersQuery}`);

      if (success) {
        this.dataOrder = data;
        this.statusSelected = data.status;
        this.optin = data.optin === 1;

        const {
          next,
          prev,
          success: success1,
        } = await ApiCMS.getOne(`orders/${idOrder}/nextprev/?${this.filtersQuery}`);

        if (success1) {
          this.nextEntryNumber = next?.id;
          this.prevEntryNumber = prev?.id;
        }

        if (data.status === 'shipped') {
          this.showTrackingNumber = true;
          this.trackingNumber = data.tracking_id;
          this.shippingStatusSelected = data.tracking_id ? 'sent' : 'no_sent';
        } else {
          this.showTrackingNumber = false;
        }
      }
    },
    async getInfoNextOrder() {
      this.getInfoOrder(this.nextEntryNumber);
    },
    async getInfoPrevOrder() {
      this.getInfoOrder(this.prevEntryNumber);
    },
    handleChangeStatus() {
      if (this.statusSelected === 'shipped') {
        this.showTrackingNumber = true;
      } else {
        this.showTrackingNumber = false;
        this.shippingStatusSelected = 'no_sent';
      }
    },
    handleClose() {
      this.$root.$emit('closeModal');
    },
    async handleSave() {
      const isSent = this.statusSelected === 'shipped' && this.trackingNumber;

      const payload = {
        status: this.statusSelected,
        shipping_status: (isSent) ? 'sent' : 'no_sent',
        tracking_number: this.trackingNumber,
      };

      if (isSent) {
        this.shippingStatusSelected = 'sent';
      } else {
        this.shippingStatusSelected = 'no_sent';
      }

      const { success } = await ApiCMS.post(`orders/${this.dataOrder.id}/status`, payload);

      if (success) {
        this.$emit('updateRowTable', {
          idRow: this.dataOrder.id,
          status: this.statusSelected,
        });

        this.$message({
          message: 'Status changed correctly.',
          showClose: true,
          type: 'success',
        });
      } else {
        this.$message({
          message: 'Error changing the status.',
          showClose: true,
          type: 'error',
        });
      }
    },
    handlePrevious() {
      this.getInfoPrevOrder(this.dataOrder.id);
    },
    handleNext() {
      this.getInfoNextOrder(this.dataOrder.id);
    },
    download(url, name) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = URL.createObjectURL(xhr.response);
        a.download = `${name}.jpg`;
        a.click();
        URL.revokeObjectURL(a.href);
      };
      xhr.onerror = () => {
        console.error('Failed to load image');
      };
      xhr.open('GET', url, true);
      xhr.send();
    },
  },
};
</script>

<style lang="scss">
.view-modal {

  .el-dialog.is-fullscreen {
    width: 100% !important;
  }

  .d-flex {
    display: flex;
  }

  .flex-direction {
    flex-direction: column;
  }

  .w-50 {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .w-100 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .img-face {
    max-height: 500px;
    max-width: 400px;
    object-fit: contain;
    padding: 30px;

    @media (max-width: 480px) {
      max-width: 360px
    }

    @media (max-width: 410px) {
      max-width: 325px;
    }

    @media (max-width: 350px) {
      max-width: 288px;
    }
  }

  .container-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .img-face-content{
    display: grid;
    justify-items: center;

    .el-button{
      height: fit-content !important;
    }
  }

  .el-dialog__body {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1140px) {
      grid-template-columns: 1fr;
    }

    height: 100%;
    padding: 20px 0 0 0;

    .container-info {
      display: flex;
      flex-direction: column;
      border-right: 1px solid #ECECEC;
      box-shadow: 0px 30px 30px rgb(0 0 0 / 10%);

      .information {
        padding: 30px;
        display: flex;
        flex-direction: column;

        h2 {
          margin-top: 0;
          margin-bottom: 5px;
        }

        .form-info {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 15px;
          padding-top: 20px;

          label {
            font-weight: 800;
            padding-bottom: 5px;
          }
        }
      }

      .container-buttons-next {
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        border-top: 1px solid #E5E5E5;
        padding: 25px;
      }
    }

    .container-radio {
      display: flex;
      flex-direction: column;

      div.container-radios {
        padding-left: 40px;

        @media (max-width: 1140px) {
          padding-bottom: 30px;
        }

        div.el-radio-group {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 15px;

          @media (max-width: 1385px) {
            grid-template-columns: 1fr 1fr;
          }

          @media (max-width: 480px) {
            grid-template-columns: 1fr;
            place-items: center;
          }

          label {
            width: 180px;
            margin-left: 0;
          }
        }
      }

      .container-buttons {
        padding: 32px;
        display: grid;
        justify-content: end;
        margin-top: 20px;
        gap: 15px;

        grid-template-columns: 1fr 250px;

        @media (max-width: 1300px) {
          grid-template-columns: 1fr;
          place-items: center;
        }

        div:first-child {
          button {
            width: 150px;
            max-height: 54px;
            font-size: 16px;
          }
        }

        div:last-child {
          display: flex;
          justify-content: end;

          button {
            width: 155px;
            max-height: 54px;
            font-size: 16px;
          }
        }
      }
    }

    .input-tracking-number {
      padding: 18px 0;
    }
  }

  .el-dialog__footer {
    display: flex;
    justify-content: space-between;
  }
}
</style>

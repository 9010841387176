import axios from 'axios';
import store from '@/store';

export default {
  async setBase() {
    axios.defaults.headers.common.token = process.env.VUE_APP_API_KEY;
    axios.defaults.baseURL = process.env.VUE_APP_API_BASE;
  },
  async getToken() {
    if (store.getters.isLoggedIn && !store.getters.isAuthExternal && store.getters.isRefreshToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${process.env.VUE_APP_API_KEY}`;
    } else {
      axios.defaults.headers.common.Authorization = null;
    }
  },
  // CRUD methods----------------------------------------------------------------------
  async get(path) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .get(path);
      const {
        data,
      } = response || {};

      const {
        total_records: total,
        page,
        page_size: pageSize,
        total_pages: totalPages,
      } = data || {};

      const result = {
        success: data.success,
        data: data ? ([...data.data] || []) : null,
        paginator: {
          page: page || null,
          page_size: pageSize || null,
          total_pages: totalPages || null,
          total: total || 0,
        },
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async getOne(path) {
    try {
      await this.setBase();
      await this.getToken();

      const response = await axios.get(path);

      const {
        data,
      } = response || {};

      const {
        next,
        prev,
      } = data || {};

      // eslint-disable-next-line no-nested-ternary
      const dataReturn = (data && data?.order)
        ? data.order
        : null;

      const result = {
        success: data.success,
        data: dataReturn,
        next,
        prev,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response?.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async post(path, payload) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .post(path, payload);
      const {
        data,
      } = response || {};

      const result = { ...data };

      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.message ?? e.message,
      };
    }
  },

  async put(path, payload) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .put(path, payload);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async patch(path, payload) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .patch(path, payload);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : null,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },

  async delete(path) {
    try {
      await this.setBase();
      await this.getToken();
      const response = await axios
        .delete(path);
      const {
        data,
      } = response || {};

      const result = {
        success: true,
        data: data ? (data || {}) : true,
      };
      return result;
    } catch (e) {
      return {
        success: false,
        status: e.response.status,
        message: e?.response?.data?.error ?? e.message,
      };
    }
  },
};

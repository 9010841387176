<template>
  <div class="manage-2fa">
    <div
      v-loading="loading"
      v-if="!user.confirmedTwoFA"
      :class="twoFaForm.enabledTwoFA ? 'content-modal-user scroll-gray' : ''"
    >
      <el-form
        label-position="top"
        :model="twoFaForm"
        status-icon
        ref="twoFaForm"
        label-width="130px"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
        :rules="rules"
      >
        <div class="enable-2fa">
          <el-switch
            @change="handleEnableTwoFA"
            v-model="twoFaForm.enabledTwoFA"
            active-color="#3FB5E5"
            inactive-color="#CBCBCB"
            :active-value=1
            :inactive-value=0
          >
          </el-switch>
          <div class="content">
            <span>Enable 2FA</span>
            <p>2FA allows double verification of your account through an authenticator
              app code to increase security when logging into MILO.</p>
          </div>
        </div>

        <div v-if="QRCode" class="setup-authenticator">
          <div class="header">
            <el-alert
              type="info"
              description="Setup Authenticator app"
              :closable="false"
              show-icon
            >
            </el-alert>
          </div>
          <div class="body">
            <ol>
              <li>
                <div class="apps">
                  Get the Authenticator App from the
                  <div class="stores">
                    <a target="_blank"
                       href="https://apps.apple.com/us/app/google-authenticator/id388497605?platform=iphone">
                      <img :src="appleStoreLogo" alt="appleStoreLogo">
                    </a>
                    or
                    <a target="_blank"
                       href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US">
                      <img :src="playStoreLogo" alt="playStoreLogo">
                    </a>
                  </div>
                </div>
              </li>
              <li>In the App select <b>Set up account.</b></li>
              <li>Choose <b>Scan barcode</b> or manually enter the code <br/>
                <div class="code">
                  <span>{{ keyCode }}</span>
                </div>
                <div class="img-code">
                  <el-image
                    style="width: 200px; height: 200px"
                    :src="QRCode"
                    fit="cover">
                  </el-image>
                </div>
              </li>

              <li>
                <div>
                  Confirm your configuration by submitting a <b>OTP</b>
                </div>
                <br/>
                <div>
                  <el-form-item label="" prop="code">
                    <el-input
                      type="text"
                      placeholder="Insert code here..."
                      v-model="twoFaForm.code"
                      autocomplete="off"
                      v-mask="'######'"
                    ></el-input>
                    <div v-if="!codeIsValid" class="el-form-item__error">
                      Invalid OTP! Please try again.
                    </div>
                  </el-form-item>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <div v-if="QRCode" class="footer-authentication">
          <el-form-item class="text-center">
            <el-button
              class="btn-secondary cancel"
              type="info"
              @click="$router.push({path: '/'})"
              @keypress="$router.push({path: '/'})"
            >
              Cancel
            </el-button>
            <el-button
              class="btn-secondary submit"
              :loading="loading"
              type="info"
              @click="validateForm('twoFaForm')"
              @keypress="validateForm('twoFaForm')"
            >
              Submit
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div
      v-else
      v-loading="loading"
      class="two-fa-active"
    >
      <div>
        <el-alert
          type="info"
          description="2 Factor Authentication is enabled on your account at all times."
          :closable="false"
          effect="dark"
          show-icon>
        </el-alert>
      </div>

      <div>
        <el-button
          class="btn-secondary"
          :loading="loading"
          type="info"
          @click="handleRemoveTwoFa()"
          @keypress="handleRemoveTwoFa()"
        >
          Remove Authenticator App
        </el-button>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import playStoreLogo from '@/assets/play_store.png';
import appleStoreLogo from '@/assets/apple_store.png';
import ApiSSO from '../../lib/ApiSSO';

export default {
  name: 'ManageTwofa',
  components: {},
  props: ['showModal', 'title', 'data'],
  data() {
    return {
      playStoreLogo,
      appleStoreLogo,
      dialogVisible: this.showModal,
      loading: false,
      twoFaForm: {
        enabledTwoFA: false,
        confirmedTwoFA: false,
        userId: '',
        applicationId: '',
        code: '',
      },
      rules: {
        code: [
          {
            required: true,
            message: 'Please enter OTP.',
            trigger: 'blur',
          },
          {
            min: 6,
            message: 'OTP must be at least 6 characters.',
            trigger: 'blur',
          },
        ],
      },
      QRCode: '',
      keyCode: '',
      codeIsValid: true,
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setPermissions', 'setRefreshToken']),
    handleClose() {
      this.$root.$emit('closeModal');
    },
    handleEnableTwoFA() {
      this.loading = true;

      this.twoFaForm.userId = this.user.id;
      this.twoFaForm.applicationId = this.user.applicationId;

      ApiSSO.post('/twoFaEnable', this.twoFaForm)
        .then((r) => {
          if (r) {
            if (r.success) {
              const {
                accessToken,
                refreshToken,
                QRCode,
                keyCode,
                permissions,
              } = r.data;

              const user = this.$jwt.decode(accessToken).data;

              this.QRCode = QRCode;
              this.keyCode = keyCode;

              this.setUser(user);
              this.setPermissions(permissions);
              this.setToken(accessToken);
              this.setRefreshToken(refreshToken);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateForm(formName) {
      this.loading = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return this.sendCode();
        }
        this.loading = false;
        return false;
      });
    },
    sendCode() {
      this.loading = true;

      this.twoFaForm.userId = this.user.id;
      this.twoFaForm.applicationId = this.user.applicationId;

      ApiSSO.post('/twoFaConfirm', this.twoFaForm)
        .then((r) => {
          if (r) {
            if (r.success) {
              const {
                accessToken,
                refreshToken,
                codeIsValid,
                permissions,
              } = r.data;

              const user = this.$jwt.decode(accessToken).data;

              this.codeIsValid = codeIsValid;

              this.setUser(user);
              this.setPermissions(permissions);
              this.setToken(accessToken);
              this.setRefreshToken(refreshToken);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleRemoveTwoFa() {
      this.loading = true;

      this.$confirm('Are you sure to remove 2 Factor Authentication?', {
        type: 'warning',
      })
        .then(() => {
          this.removeTwoFa();
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeTwoFa() {
      this.loading = true;

      this.twoFaForm.userId = this.user.id;
      this.twoFaForm.applicationId = this.user.applicationId;

      ApiSSO.post('/twoFaRemove', this.twoFaForm)
        .then((r) => {
          if (r) {
            if (r.success) {
              const {
                accessToken,
                refreshToken,
                permissions,
              } = r.data;

              const user = this.$jwt.decode(accessToken).data;

              this.setUser(user);
              this.setPermissions(permissions);
              this.setToken(accessToken);
              this.setRefreshToken(refreshToken);

              this.twoFaForm.enabledTwoFA = this.user.enabledTwoFA;
              this.twoFaForm.confirmedTwoFA = this.user.confirmedTwoFA;
              this.twoFaForm.code = '';
              this.QRCode = '';
              this.keyCode = '';
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.twoFaForm.enabledTwoFA = this.user.enabledTwoFA;
    if (this.user.enabledTwoFA && !this.user.confirmedTwoFA) {
      this.handleEnableTwoFA();
    }
  },
};
</script>

<style lang="scss">
.manage-2fa {
  margin-top: 58px;
  @media (min-width: 720px) {
    margin-left: 20px;
  }

  .two-fa-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }

  .setup-authenticator {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FDFEFF;
    box-shadow: 0px 4px 12px rgba(98, 96, 96, 0.25);
    border-radius: 4px;
    @media (max-width: 768px) {
      width: 100%;
    }

    .header {
      width: 100%;

      .el-alert.el-alert--info {
        background: #E8F8FF;
        height: 44px;

        p {
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #3FB5E5;
        }

        i {
          color: #3FB5E5;
        }
      }
    }

    ol {
      list-style: decimal !important;
      @media (max-width: 768px) {
        font-size: 14px;
        padding-left: 25px;
      }

      li {
        padding: 10px;

        &::marker {
          font-weight: bold !important;
        }

        .img-code {
          display: flex;
          justify-content: start;
        }

        .code {
          display: flex;
          justify-content: start;
          background: #E9E9E9;
          width: max-content;
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.17em;
          color: #3B3936;
          padding: 0 15px;
          margin: 20px 0;
          z-index: 1;
          position: relative;
        }
      }
    }

    .apps {
      display: flex;
      align-items: center;
    }

    .stores {
      display: flex;
      column-gap: 2px;
      justify-content: center;
      align-items: center;
      padding-left: 5px;

      img {
        height: 24px;
        @media (max-width: 768px) {
          height: 20px;
        }
      }

    }
  }

  .content-modal-user {
    @media (min-width: 720px) {
      padding: 0 20px 0 0;
    }
  }

  .enable-2fa {
    display: flex;
    column-gap: 18px;

    span {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 110%;
      color: #363738;
    }

    p {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #454444;
    }
  }

  .footer-authentication {
    height: 80px;
    background: #FFFFFF;
    border-top: 1px solid #E5E5E5;
    margin-top: 50px;
    display: flex;
    justify-content: end;
    padding-top: 20px;

    .cancel {
      width: 96px;
      height: 36px;
      background: #F1F1F1;
      border-radius: 4px;
      border: 0;
      color: #363738;
    }

    .submit {
      width: 134px;
      height: 36px;
      background: #B5BABC;
      border-radius: 4px;
      border: 0;
      color: #FFFFFF;
    }
  }
}
</style>

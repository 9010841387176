import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: () => ({
    user: null,
    token: null,
    permissions: null,
    refreshToken: null,
    authExternal: null,
  }),
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    isRefreshToken(state) {
      return !!state.refreshToken;
    },
    isAuthExternal(state) {
      return !!state.authExternal;
    },
    isValidPermission: (state) => (identifier) => {
      if (!state.permissions) {
        return false;
      }

      const decodePermission = JSON.parse(window.atob(state.permissions));

      return decodePermission.includes(identifier);
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    setToken(state, token) {
      state.token = token;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    setAuthExternal(state, authExternal) {
      state.authExternal = authExternal;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin],
});

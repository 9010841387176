<template>
  <div class="home">
    <div class="container-cards-count">
      <el-card
        v-for="(value, key) in orderStatusPermitted"
        :key="value + key"
        class="box-card"
      >
        <span :class="key === 'failed' ? 'red-color' : 'blue-color'">
          {{ cardsByType[key] ?? 0 }}
        </span>
        <span class="capitalize">
          {{ handleTextCard(key) }}
        </span>
      </el-card>
    </div>

    <simple-table
      v-if="infoTable"
      :data="infoTable"
      :firstColumn="firstColumn"
      :loading="loading"
      :showDateRange="false"
      :showFilterState="true"
      :showFilterStatus="true"
      :showSearch="true"
      :states="listStates"
      :statusTypes="statusTypes"
      :statusTypesPermissions="statusTypesPermissions"
      :tableColumns="columns"
      @download="downloadCsv"
      @filterByState="handleFilterState"
      @filterByStatus="handleFilterStatus"
      @searchRow="handleSearch"
      @sortColumn="sortColumn"
      @viewRow="viewData"
      placeholderSearch="Search by name or email"
    ></simple-table>

    <simple-paginator
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :currentPage="paginator.currentPage"
      :pageSize="paginator.pageSize"
      :total="paginator.total"
      :pagerCount="5"
      layout="total, sizes, prev, pager, next, jumper"
      class="container"
    ></simple-paginator>

    <data-modal
      v-if="showModalView"
      :data="dataItem"
      :filtersQuery="filtersQuery"
      :showModal="showModalView"
      :statusTypesPermissions="statusTypesPermissions"
      @updateRowTable="updateRowTable"
    ></data-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SimpleTable from '@/components/Tables/SimpleTable.vue';
import SimplePaginator from '@/components/Paginator/SimplePaginator.vue';
import DataModal from '@/components/Modals/DataModal.vue';
import ApiCMS from '@/lib/ApiCMS';

export default {
  name: 'HomeView',
  components: {
    SimpleTable,
    SimplePaginator,
    DataModal,
  },
  data() {
    return {
      cardsByType: [],
      filterSearch: '',
      filterSortBy: '',
      filtersQuery: '',
      filterState: '',
      filterStatus: '',
      infoTable: [],
      listStates: [],
      statusTypesPermissions: {
        refund: 'JAMESON_JACKETS_VIEW.JAMESON_JACKETS_REFUND',
        onhold: 'JAMESON_JACKETS_VIEW.JAMESON_JACKETS_ON_HOLD',
        pending: 'JAMESON_JACKETS_VIEW.JAMESON_JACKETS_PENDING',
        shipped: 'JAMESON_JACKETS_VIEW.JAMESON_JACKETS_SHIPPED',
        download_csv: 'JAMESON_JACKETS_VIEW.JAMESON_JACKETS_VIEW_JAMESON_JACKETS_DOWNLOAD_CSV',
      },
      statusTypes: {
        pending: 'info',
        shipped: 'success',
        onhold: 'warning',
        refund: 'success',
      },
      columns: [
        {
          prop: 'id',
          label: 'Entry #',
          sortable: true,
          width: '140',
        },
        {
          prop: 'firstname',
          label: 'Name',
          sortable: true,
          width: '',
        },
        {
          prop: 'email',
          label: 'E-mail',
          sortable: true,
          width: '',
        },
        {
          prop: 'billing_state',
          label: 'State',
          sortable: false,
          width: '140',
          type: 'billing_state',
        },
        {
          prop: 'status',
          label: 'Status',
          sortable: true,
          width: '200',
          type: 'status',
        },
      ],
      firstColumn: {
        show: true,
        icon: 'mdi-chart-box-outline',
      },
      loading: true,
      visible: false,
      showModalView: false,
      dataItem: {},
      totalData: [],
      paginator: {
        currentPage: 0,
        pageSize: 5,
        total: 0,
        page: 1,
      },
    };
  },
  computed: {
    ...mapGetters(['isValidPermission']),
    orderStatusPermitted() {
      const cleanedStatusTypes = Object.fromEntries(
        Object.entries(this.statusTypesPermissions).filter((item) => item[0] !== 'download_csv'),
      );
      return cleanedStatusTypes;
    },
  },
  methods: {
    async getTotalOrders() {
      const params = {
        page_size: 100000,
        page: 1,
      };

      const searchParams = new URLSearchParams(params);

      const { data, success } = await ApiCMS.get(`orders/?${searchParams}`);

      if (success) {
        this.totalData = data;

        this.setStatusCount(data);
        this.listStates = data.reduce((acc, item) => {
          const key = item.billing_state;

          if (!acc[key]) {
            acc[key] = 0;
          }

          acc[key] += 1;

          return acc;
        }, {});
      }
    },
    async getOrders() {
      try {
        this.statusTypesPermissions = Object.keys(this.statusTypesPermissions)
          .filter((key) => this.isValidPermission(this.statusTypesPermissions[key]))
          .reduce(
            (cur, key) => Object.assign(cur, { [key]: this.statusTypesPermissions[key] }),
            {},
          );

        const statusByComma = Object.keys(this.statusTypesPermissions)
          .map((key) => key)
          .join(',');

        const params = {
          order_by: this.filterSortBy ? this.filterSortBy.property : 'id',
          order_direction: this.filterSortBy ? this.filterSortBy.order : 'desc',
          page_size: this.paginator.pageSize,
          page: this.paginator.page,
          search: this.filterSearch,
          status: this.filterStatus || statusByComma,
          state: this.filterState,
        };

        const searchParams = new URLSearchParams(params);

        this.filtersQuery = searchParams.toString();

        const {
          data,
          paginator,
          success,
        } = await ApiCMS.get(`orders/?${searchParams}`);

        if (success) {
          this.infoTable = data;

          this.paginator.currentPage = paginator.page;
          this.paginator.total = paginator.total;
        }
      } catch (error) {
        console.error(error);
      }
    },
    setStatusCount(data) {
      this.cardsByType = data.reduce((acc, item) => {
        const key = item.status;

        if (!acc[key]) {
          acc[key] = 0;
        }

        acc[key] += 1;

        return acc;
      }, {});
    },
    handleTextCard(type) {
      if (type === 'onhold') {
        return 'On Hold';
      }
      if (type === 'refund') {
        return 'Refund';
      }

      return type;
    },
    closeModal() {
      this.showModalView = false;
    },
    viewData(row) {
      const dataItem = [];
      this.showModalView = true;
      dataItem.push({
        idOrder: row.id,
        name: `Order ${row.firstname} ${row.lastname}`,
        ...row,
      });
      const [arr] = dataItem;
      this.dataItem = arr;
    },
    currentChange(val) {
      this.paginator.page = val;
      this.getOrders();
    },
    sizeChange(val) {
      this.paginator.pageSize = val;
      this.paginator.handlesSizeChange = true;
      this.getOrders();
    },
    sortColumn(sortProps) {
      this.filterSortBy = sortProps;
      this.getOrders();
    },
    updateRowTable({ idRow, status }) {
      const indexRow = this.infoTable.findIndex((element) => element.id === idRow);

      this.infoTable[indexRow].status = status;
    },
    handleSearch(search) {
      this.filterSearch = search;
      this.paginator.page = 1;
      this.getOrders();
    },
    handleFilterStatus(status) {
      this.filterStatus = status;
      this.getOrders();
    },
    handleFilterState(status) {
      this.filterState = status;
      this.getOrders();
    },
    async downloadCsv() {
      const { data: url, success } = await ApiCMS.post('downloadCsv');

      if (success) {
        window.open(url, '_blank');

        setTimeout(() => {
          ApiCMS.get('deleteCsv');
        }, 5000);
      }
    },
  },
  created() {
    this.getOrders();
    this.getTotalOrders();
  },
  mounted() {
    this.loading = false;
    this.$root.$on('closeModal', this.closeModal);
  },
};
</script>

<style lang="scss">
.capitalize {
  text-transform: capitalize;
}

.blue-color {
  color: #3FB5E5;
}

.red-color {
  color: #DE5E5E;
}

.home {
  padding: 0 50px;

  h1 {
    padding-top: 10px;
  }
}

.container-cards-count {
  display: flex;
  gap: 15px;
  padding: 20px 0;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    place-items: center;
  }

  @media (max-width: 730px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
  }

  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 400px) {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
  }
}

.box-card {
  width: 160px;

  .el-card__body {
    display: flex;
    flex-direction: column;
    align-items: center;

    span:first-child {
      font-size: 32px;
    }

    span:last-child {
      padding-top: 7px;
      color: #6C6E6F;
    }
  }
}
</style>

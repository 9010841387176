<template>
  <div class="connect-sso-container">
    <div id="app">
      <el-container direction="vertical" :class="isLoggedIn ? '' : 'login'">
          <NavBar v-if="isLoggedIn"></NavBar>

          <el-main>
            <div v-if="showBackButton" class="back-btn">
              <el-tooltip
                class="item"
                effect="dark"
                content="Go to back"
                placement="right">
                <el-button
                  @click="goBack">
                    <i class="mdi mdi-arrow-left"></i>Back
                </el-button>
              </el-tooltip>
            </div>
            <router-view/>
          </el-main>

          <!-- <img
            v-if="!isLoggedIn"
            class="img-login"
            :src="logoBg"
            alt="Login Image"
          > -->
        </el-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import jwtDecode from 'jwt-decode';
import ApiSSO from '@/lib/ApiSSO';
// import logoBg from '@/assets/login-bg.png';
import NavBar from './components/NavBar/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar,
  },
  data() {
    return {
      // logoBg,
      showBackButton: false,
      intervalVerifyToken: null,
      flag: false,
      refreshTokenOld: null,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  watch: {
    $route(val) {
      this.showBackButton = (val.path !== '/' && val.path !== '/login');
      document.title = `JAMESON SPE CMS | ${val.name}`;
    },
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'refreshToken']),
    ...mapState(['token', 'user', 'refreshToken']),
  },
  methods: {
    ...mapMutations(['setUUID', 'setUser', 'setToken', 'setPermission', 'setRefreshToken']),
    goBack() {
      this.$router.go(-1);
    },
    setIntervalVerifyToken() {
      this.intervalVerifyToken = setInterval(async () => {
        if (this.user) {
          await this.validateToken();
        }
      }, 60000);
    },
    async validateToken() {
      const now = Date.now() / 1000;
      const exp = +this.user.exp;
      if (now + 30 >= exp && !this.flag) {
        this.flag = true;
        // refresh token
        console.log('token expired, refresh token', new Date());
        try {
          this.refreshTokenOld = this.refreshToken;
          this.setRefreshToken(null);
          const r = await ApiSSO.post('/refreshToken', {
            applicationId: this.user.applicationId,
            refreshToken: this.refreshTokenOld,
          });
          if (r.success) {
            const { accessToken, refreshToken, permissions } = r.data;
            const user = jwtDecode(accessToken).data;

            this.setUser(user);
            this.setPermissions(permissions);
            this.setToken(accessToken);
            this.setRefreshToken(refreshToken);
            return;
          }

          this.setUser(null);
          this.setToken(null);
          this.setPermissions(null);
          this.setRefreshToken(null);
          this.$router.push({
            path: '/login',
            query: { redirect: this.$route.fullPath },
          });
        } catch (e) {
          console.error(e);
          this.setUser(null);
          this.setPermissions(null);
          this.setToken(null);
          this.setRefreshToken(null);
          this.$router.push({
            path: '/login',
            query: { redirect: this.$route.fullPath },
          });
        } finally {
          this.flag = false;
        }
      } else {
        // console.log('no refresh token', new Date());
      }
    },
    created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    mounted() {
      this.setIntervalVerifyToken();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      clearInterval(this.intervalVerifyToken);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/style.scss';
.back-btn {
    margin-right: 10px;
    position: relative;
    z-index: 999;
    i{
      font-weight: bold;
      font-size: 16px;
    }
    .el-button{
      border: none;
      position: absolute;
      left: 10px;
      top: 52px;
      background-color: transparent;
      text-align: left;
      font-size: 16px;
      padding-left: 0;
      padding-right: 0;
      color: #3FB5E5 ;
      @media (min-width: 720px){
        left: 30px;
      }
    }

    .el-button:hover{
      background-color: transparent;
      border: none;
    }
    .el-button:active{
      background-color: transparent;
      border: none;
    }
    .el-button:focus{
      background-color: transparent;
      border: none;
    }
  }

.connect-sso-container {
  * {
    font-family: 'Work Sans';
  }
}

div[role=tooltip] {
  font-family: "Work Sans";
}
</style>
